import { createStore } from "vuex";

export default createStore({
  state: {
    me: {
      permissions: {
        staffprofile:[],
        group:[],
        childcarehouse:[],
        childhealthmeasurements:[],
        childprofile:[],
      },
      nome: "",
      doctipo: "",
      docnum: "",
      telefone: "",
      lar: "",
      nomePerfil: "",
    },
    criancas: [

    ],
    lares: [
    ],
    equipe:[

    ],
    historicoCompleto:{}
  },
  getters: {
    criancas: (state) => state.criancas,
    lares: (state) => state.lares,
    me:(state)=>state.me,
    equipe:(state)=>state.equipe,
    historicoCompleto:(state)=>state.historicoCompleto
  },
  mutations: {
    setCriancas(state, criancas) {
      state.criancas = criancas;
    },
    setLares(state, lares) {
      state.lares = lares;
    },
    setMe(state,me){
      state.me = me
    },
    setEquipe(state,equipe){
      state.equipe = equipe
    },
    setHistoricoCompleto(state,historicoCompleto){
      state.historicoCompleto = historicoCompleto
    }
  },
  actions: {
    updateCriancas({ commit }, criancas) {
      commit("setCriancas", criancas);
    },
    updateLares({ commit }, lares) {
      commit("setLares", lares);
    },
    updateMe({commit}, me){
      commit("setMe", me)
    },
    updateEquipe({commit}, equipe){
      commit("setEquipe", equipe)
    },
    updateHistoricoCompleto({commit}, historicoCompleto){
      commit("setHistoricoCompleto", historicoCompleto)
    },
  },
});
