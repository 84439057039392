import { createRouter, createWebHashHistory } from 'vue-router'
import Cookies from "js-cookie";

const routes = [
  {
    path: '/:expired?',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'menu',
    component: () => import(/* webpackChunkName: "about" */ '../views/MenuView.vue')
  },
  {
    path: '/agentes',
    name: 'agentes',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListaAgentesView.vue')
  },
  {
    path: '/dados-agente/:id',
    name: 'dados-agente',
    component: () => import(/* webpackChunkName: "about" */ '../views/DadosAgenteView.vue')
  },
  {
    path: '/criancas',
    name: 'criancas',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListaCriancasView.vue')
  },
  {
    path: '/crianca/:id',
    name: 'crianca',
    component: () => import(/* webpackChunkName: "about" */ '../views/CriancaView.vue')
  },
  {
    path: '/dados-crianca/:id',
    name: 'dados-crianca',
    component: () => import(/* webpackChunkName: "about" */ '../views/DadosCriancaView.vue')
  },
  {
    path: '/dados-saude/:id/:toast?',
    name: 'dados-saude',
    component: () => import(/* webpackChunkName: "about" */ '../views/DadosSaudeView.vue')
  },
  {
    path: '/meu-perfil',
    name: 'meu-perfil',
    component: () => import(/* webpackChunkName: "about" */ '../views/MeuPerfilView.vue')
  },
  // {
  //   path: '/tipos',
  //   name: 'tipo',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ListaTiposView.vue')
  // },
  // {
  //   path: '/novo-tipo',
  //   name: 'novo-tipo',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/NovoTipoPerfilView.vue')
  // },
  // {
  //   path: '/tipo-usuario',
  //   name: 'tipo-usuario',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/TipoUsuarioView.vue')
  // },
  {
    path: '/novo-user/:type',
    name: 'novo-user',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovoUsuarioView.vue')
  },
  {
    path: '/novo-cadastro',
    name: 'novo-cadastro',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovoCadastroView.vue')
  },
  {
    path: '/novo-agente',
    name: 'novo-agente',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovoAgenteView.vue')
  },
  {
    path: '/questionario-curva/:id',
    name: 'questionario-curva',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioCurvaView.vue')
  },
  {
    path: '/questionario-parto/:id',
    name: 'questionario-parto',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioPartoView.vue')
  },
  {
    path: '/questionario-escolar/:id',
    name: 'questionario-escolar',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioEscolarView.vue')
  },
  {
    path: '/questionario-vacina/:id',
    name: 'questionario-vacina',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioVacinaView.vue')
  },
  {
    path: '/questionario-sangue/:id',
    name: 'questionario-sangue',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioSangueView.vue')
  },
  {
    path: '/questionario-visao/:id',
    name: 'questionario-visao',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioVisaoView.vue')
  },
  {
    path: '/questionario-audicao/:id',
    name: 'questionario-audicao',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioAudicaoView.vue')
  },
  {
    path: '/questionario-psico/:id',
    name: 'questionario-psico',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioPsicoView.vue')
  },
  {
    path: '/questionario-bucal/:id',
    name: 'questionario-bucal',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioBucalView.vue')
  },
  {
    path: '/questionario-sexual/:id',
    name: 'questionario-sexual',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioSexualView.vue')
  },
  {
    path: '/questionario-medicamentos/:id',
    name: 'questionario-medicamentos',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuestionarioMedicamentosView.vue')
  },
  // {
  //   path: '/avaliacao-saude/:id',
  //   name: 'avaliacao-saude',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AvaliacaoSaudeView.vue')
  // },
  {
    path: '/historico-saude/:id',
    name: 'historico-saude',
    component: () => import(/* webpackChunkName: "about" */ '../views/HistoricoSaudeView.vue')
  },
  {
    path: '/historico/:id',
    name: 'historico',
    component: () => import(/* webpackChunkName: "about" */ '../views/HistoricoView.vue')
  },
  {
    path: '/lares',
    name: 'lares',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListaLaresView.vue')
  },
  {
    path: '/novo-lar',
    name: 'novo-lar',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovoLarView.vue')
  },
  {
    path: '/lar/:id',
    name: 'lar',
    component: () => import(/* webpackChunkName: "about" */ '../views/LarView.vue')
  },
  {
    path: '/novo-evento/:id',
    name: 'novo-evento',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventoSaudeView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = !!Cookies.get("api-auth-token")

  if (isAuthenticated) {
    next()
    return
  }

  let requiresAuth = [
    'novo-user',
    'novo-cadastro',
    'novo-agente',
    'meu-perfil',
    'dados-crianca',
    'dados-saude',
    'questionario-curva',
    'questionario-parto',
    'questionario-escolar',
    'questionario-vacina',
    'questionario-sangue',
    'questionario-visao',
    'questionario-audicao',
    'questionario-psico',
    'questionario-bucal',
    'questionario-sexual',
    'questionario-medicamentos',
    'avaliacao-saude',
    'historico-saude',
    'crianca',
    'criancas',
    'agentes',
    'dados-agente',
    'lares',
    'novo-lar',
    'lar',
    'menu',
    'novo-evento',
  ];
  
  if (requiresAuth.includes(to.path))
    next({ name: "Login" });
  else next();
});


export default router


